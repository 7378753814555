import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { motion } from "framer-motion";
import "react-toastify/dist/ReactToastify.css";
import logo from "../assets/Products/auger4.jpg"

const Toast = () => {
  useEffect(() => {
    // Show the announcement after 10 seconds
    const timer = setTimeout(() => {
      toast.info(
        <motion.div
          initial={{ x: "100%" }}
          animate={{ x: "0" }}
          exit={{ x: "100%" }}
          transition={{ duration: 0.3, ease: "easeInOut" }}
          className="bg-white p-4 rounded-lg shadow-lg max-w-md mx-auto md:max-w-xl flex flex-col items-center"
        >
          <img
            src={logo} 
            alt="Announcement"
            className="w-48 h-24 md:w-64 md:h-32 object-cover mb-4"
          />
          <motion.h2
            className="text-xl md:text-2xl font-bold mb-2 text-red-600 text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            We are thrilled to be the Sole Agent of Auger Brand In Kenya!
          </motion.h2>
          <motion.p
            className="text-sm md:text-base text-center"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            Auger is one of the world’s leading suppliers of spare parts for
            commercial vehicles in the Independent Aftermarket. Auger values
            good quality, service, reliability and innovation, reaching over 60
            countries around the world with more than 35.000 references of spare
            parts.
          </motion.p>
        </motion.div>,
        {
          position: "bottom-right",
          autoClose: 8000,
          closeOnClick: true,
          draggable: true,
        }
      );
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <ToastContainer />
    </>
  );
};

export default Toast;
