import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import logo from "../assets/imageedit_2_5008981203.jpg"

const Landing = () => {
  const titles = [
    "Welcome to European Star",
    "Find Quality Spare Parts",
    "Enhance Your Truck Performance",
  ];
  const [currentTitleIndex, setCurrentTitleIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTitleIndex((prevIndex) =>
        prevIndex === titles.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, [titles]);

  return (
    <div
      id="/"
      className="min-h-screen flex items-center justify-center bg-gray-900"
      style={{
        backgroundImage: `url(${logo})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <div className="text-white text-center px-4">
        <motion.h1
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-4xl md:text-5xl font-bold mb-4 md:mb-6"
        >
          {currentTitleIndex === 0 ? (
            <>
              Welcome to{" "}
              <span className="font-black font-sans italic">European Star</span>
            </>
          ) : (
            titles[currentTitleIndex]
          )}
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.3 }}
          className="text-lg md:text-xl italic text-white"
        >
          Your one-stop shop for all your Mercedes Trucks spare parts needs.
        </motion.p>
      </div>
    </div>
  );
};

export default Landing;
