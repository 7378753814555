import React from 'react';
import { motion } from 'framer-motion';
import logo1 from "../assets/Products/engine.png"
import logo2 from "../assets/Products/shocks.avif"
import logo3 from "../assets/Products/gearbox.jpg"
import logo4 from "../assets/Products/diff.jpg"
import logo5 from "../assets/Products/service parts.avif"
import logo6 from "../assets/Products/headlamps.jpg"
import logo7 from "../assets/Products/engine oil.avif"
import logo8 from "../assets/Products/sensors.webp"
import logo9 from "../assets/Products/trailer parts.jpg"
const Products = () => {
  const products = [
    {
      name: 'Engine Parts',
      description: 'High-quality engine components for optimal performance.',
      image: logo1,
    },
    {
      name: 'Suspension Systems',
      description: 'Enhance your vehicle\'s ride and handling with our suspension systems.',
      image: logo2,
    },
    {
      name: 'GearBox Parts',
      description: 'Quality parts for your vehicle\'s gearbox system.',
      image: logo3,
    },
    {
      name: 'Differential Parts',
      description: 'Premium differential parts for improved performance.',
      image: logo4,
    },
    {
      name: 'Service Parts',
      description: 'Essential parts for regular vehicle servicing.',
      image: logo5,
    },
    {
      name: 'HeadLamps',
      description: 'High-quality headlamps for improved visibility.',
      image: logo6,
    },
    {
      name: 'Engine Oils',
      description: 'Top-grade engine oils for optimal lubrication.',
      image: logo7,
    },
    {
      name: 'Sensors',
      description: 'Advanced sensor systems for precise data monitoring in trucks.',
      image: logo8,
    },
    {
      name: 'Trailer Parts',
      description: 'High-quality parts for trailers.',
      image: logo9,
    },
  ];

  return (
    <div id='products' className="bg-gray-400 min-h-screen">
    <header className="py-8 px-4 sm:px-6 bg-gradient-to-r from-black to-white">
      <h1 className="text-4xl font-bold text-center text-white transition-all duration-500 transform hover:scale-110">
        Our Products
      </h1>
    </header>
    <div className="container mx-auto py-12 px-4 sm:px-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product, index) => (
          <motion.div
            key={index}
            className="bg-gray-200 shadow-lg rounded-lg p-6"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            <motion.img
              src={product.image}
              alt={product.name}
              className="mb-4 rounded-lg product-image"
              style={{ height: '350px', width: '100%' }}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
            <h2 className="text-xl font-bold mb-2 text-gray-800">
              {product.name}
            </h2>
            <motion.p
              className="text-gray-600"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
            >
              {product.description}
            </motion.p>
          </motion.div>
        ))}
      </div>
    </div>
  </div>
  );
};

export default Products;
