import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { Link } from "react-scroll";
import { motion } from 'framer-motion';
import logo from '../assets/european_star_logo-removebg-preview.png'


function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-gray-200 text-black">
      <div id='contact' className="container mx-auto py-8">
        {/* Desktop Footer */}
        <div className="hidden md:flex md:justify-between">
          {/* Left section */}
          <div className="flex items-center">
          <img src={logo} alt='Company Logo' className='h-50 w-50 max-h-12 md:max-h-16 mr-2' />
            <h2 className="text-1xl font-bold">European Star</h2>
          </div>

          {/* Middle section */}
          <div className="flex items-center">
            <ul className="flex space-x-4">
            <motion.li whileHover={{ scale: 1.1 }}>
            <Link
              activeClass="active"
                to="/"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-white">Home</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.1 }}>
          <Link
              activeClass="active"
                to="products"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-white">Products</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.1 }}>
          <Link
              activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-white">About</Link>
          </motion.li>
          <motion.li whileHover={{ scale: 1.1 }}>
          <Link
              activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-white">Contact</Link>
          </motion.li>
            </ul>
          </div>

          {/* Right section */}
          <div className="flex items-center">
            <a href="https://www.facebook.com" className="text-blue-500 hover:text-white">
              <FaFacebook className="w-6 h-6" />
            </a>
            <a href="https://instagram.com/european_star?igshid=OGQ5ZDc2ODk2ZA==" className="text-pink-500 hover:text-white ml-4">
              <FaInstagram className="w-6 h-6" />
            </a>
          </div>
        </div>

        {/* Mobile Footer */}
        <div className="md:hidden mt-4">
          <div className="flex justify-between">
          <Link
              activeClass="active"
                to="/"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="text-black hover:text-white ml-2">
              Home
            </Link>
            <Link
              activeClass="active"
                to="products"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="text-black hover:text-white">
             Products
            </Link>
            <Link
              activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="text-black hover:text-white">
              About
            </Link>
           
            <Link
              activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="text-black hover:text-white mr-2">
              Contact
            </Link>
          </div>
          <div className="flex justify-center mt-4">
            <a href="https://www.facebook.com" className="text-blue-500 hover:text-white">
              <FaFacebook className="w-6 h-6" />
            </a>
            <a href="https://instagram.com/european_star?igshid=OGQ5ZDc2ODk2ZA==" className="text-pink-500 hover:text-white ml-4">
              <FaInstagram className="w-6 h-6" />
            </a>
          </div>
        </div>

        {/* Bottom section */}
        <div className="border-t border-gray-800 mt-8 pt-4 text-sm text-black text-center">
          <p>&copy; {currentYear} European Star. All rights reserved.</p>
          <p className="mt-2">
            Designed and developed by{" "}
            <a href="mailto:abdubadhawi10@gmail.com" className="text-black hover:text-green-500 underline">
              Abdu
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
