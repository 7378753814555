import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaInstagram } from "react-icons/fa";
import { Link } from "react-scroll";
import logo from "../assets/european logo.jpg"

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const menuVariants = {
    open: {
      opacity: 1,
      y: 0,
      display: "block",
      transition: {
        ease: "easeInOut",
      },
    },
    closed: {
      opacity: 0,
      y: -20,
      display: "none",
      transition: {
        ease: "easeInOut",
      },
    },
  };

  return (
    <nav
      id="/"
      className="bg-gradient-to-r from-black to-white text-black py-4 px-6 sm:px-10 relative"
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <img
            src={logo}
            alt="Company Logo"
            className="h-9 w-auto rounded-xl mr-2"
          />
          <h1 className="font-bold text-lg text-white">European star</h1>
        </div>
        <div className="flex items-center">
          <ul className="hidden sm:flex space-x-4">
            <motion.li whileHover={{ scale: 1.1 }}>
              <Link
               activeClass="active"
                to="/"
                spy={true}
                smooth={true}
                offset={0}
                duration={500}
                className="hover:text-gray-800"
              >
                Home
              </Link>
            </motion.li>
            <motion.li whileHover={{ scale: 1.1 }}>
            <Link
             activeClass="active"
                to="products"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-gray-800">
                Products
              </Link>
            </motion.li>
            <motion.li whileHover={{ scale: 1.1 }}>
            <Link
             activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-gray-800">
                About
              </Link>
            </motion.li>
            <motion.li whileHover={{ scale: 1.1 }}>
            <Link
            activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-gray-800">
                Contact
              </Link>
            </motion.li>
          </ul>
          <a
            href="https://instagram.com/european_star?igshid=OGQ5ZDc2ODk2ZA=="
            target="_blank"
            rel="noopener noreferrer"
            className="ml-4 text-gray-800 hover:text-black"
          >
            <FaInstagram className="h-6 w-6 text-pink-600" />
          </a>
          <motion.button
            className="sm:hidden ml-4 text-gray-800 hover:text-black"
            onClick={toggleMenu}
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </motion.button>
        </div>
      </div>
      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            className={`sm:hidden mt-4`}
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            <ul className="flex text-center flex-col space-y-2">
              <motion.li whileHover={{ scale: 1.1 }}>
              <Link
              activeClass="active"
                to="/"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-gray-800">
                  Home
                </Link>
              </motion.li>
              <motion.li whileHover={{ scale: 1.1 }}>
              <Link
              activeClass="active"
                to="products"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-gray-800">
                  Products
                </Link>
              </motion.li>
              <motion.li whileHover={{ scale: 1.1 }}>
              <Link
              activeClass="active"
                to="about"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-gray-800">
                  About
                </Link>
              </motion.li>
              <motion.li whileHover={{ scale: 1.1 }}>
              <Link
              activeClass="active"
                to="contact"
                spy={true}
                smooth={true}
                offset={0}
                duration={500} className="hover:text-gray-800">
                  Contact
                </Link>
              </motion.li>
            </ul>
          </motion.div>
        )}
      </AnimatePresence>
    </nav>
  );
};

export default Navbar;
