import React from 'react';
import { FaPhone, FaMapMarkerAlt, FaEnvelope, FaInstagram, FaWhatsapp } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <div id='contact' className="bg-gray-100 min-h-screen">
      {/* Header */}
      <div className="bg-gradient-to-r from-black to-white">
        <div className="max-w-6xl mx-auto py-4 md:py-8 flex items-center justify-center">
          <h1 className="font-bold text-2xl md:text-4xl lg:text-5xl font-heading text-white">Contact Us</h1>
        </div>
      </div>

      {/* Content */}
      <div className="max-w-6xl mx-auto py-8 md:py-16 flex flex-col md:flex-row">
        {/* Left side: Google Map */}
        <div className="w-full md:w-2/3 px-2">
          <div className="h-96 md:h-128 rounded-lg overflow-hidden shadow-lg">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7822596792525!2d36.8402575!3d-1.3057591!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f110ebc3052ed%3A0x218f66b62e135a8!2sEuropean%20star%20auto%20parts!5e0!3m2!1sen!2ske!4v1688800769664!5m2!1sen!2ske"
              className="w-full h-full"
              style={{ border: 0 }}
              allowFullScreen
            ></iframe>
          </div>
        </div>

        {/* Right side: Contact information */}
        <div className="w-full md:w-1/3 px-4 mt-8 md:mt-0 flex flex-col">
          <div className="bg-white rounded-lg shadow-lg p-8">
            <div className="flex items-center justify-center mb-6">
              <FaPhone className="w-6 h-6 mr-3 text-gray-600" />
              <div className="text-center">
                <p className="text-lg font-medium leading-tight">Phone:</p>
                <a href="tel:+254722383838" className="text-gray-700 ml-5 hover:text-blue-500">+254 (0) 722383838</a>
              </div>
            </div>
            <div className="flex items-center justify-center mb-6">
              <FaMapMarkerAlt className="w-6 h-6 mr-3 text-gray-600" />
              <div className="text-center">
                <p className="text-lg font-medium leading-tight">Location:</p>
                <a href="https://goo.gl/maps/bhms4UoujmeHMirQ8" className="text-gray-700 ml-5 hover:text-blue-500">Industrial area, Enterprise Road, Opp. DTB Bank</a>
              </div>
            </div>
            <div className="flex items-center justify-center mb-6">
              <FaEnvelope className="w-6 h-6 mr-3 text-gray-600" />
              <div className="text-center">
                <p className="text-lg font-medium leading-tight">Email:</p>
                <a href="mailto:europeanstar@ymail.com" className="text-gray-700 ml-5 hover:text-blue-500">europeanstar@ymail.com</a>
              </div>
            </div>
           
            <div className="mt-8 text-center">
              <p className="text-gray-600 text-sm">Connect with us:</p>
              <div className="flex justify-center mt-2 space-x-4">
                <a href="https://instagram.com/european_star?igshid=OGQ5ZDc2ODk2ZA==" className="text-pink-600 hover:text-pink-700">
                  <FaInstagram className="w-8 h-8" />
                </a>
                <a href="https://wa.me/254722383838" className="text-green-600 hover:text-green-700">
                  <FaWhatsapp className="w-8 h-8" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
