import React from "react";
import logo1 from "../assets/Products/auger.jpeg";
import logo2 from "../assets/Products/benz.jpg";
import logo3 from "../assets/Products/mahle.jpg";
import logo4 from "../assets/Products/sachs.jpg";
import logo5 from "../assets/Products/ks.webp";
import logo6 from "../assets/Products/elring.webp";
import logo7 from "../assets/Products/wabco.png";
import logo8 from "../assets/Products/bosch.png";
import logo9 from "../assets/Products/victorreinz.png";
import logo10 from "../assets/Products/Euroricambi.png";
import logo11 from "../assets/Products/syldiesel.jpg";
import logo12 from "../assets/Products/hengst.jpg";

function Brands() {
  return (
    <div className="bg-gray-300 min-h-screen p-8">
      <div className="flex items-center bg-gradient-to-r from-black to-white justify-center py-4 md:py-8">
        <h1 className="font-bold text-2xl md:text-4xl lg:text-4xl font-heading text-white transition-all duration-500 transform hover:scale-110">
          Popular Brands
        </h1>
      </div>
      <div className="flex justify-center mt-8">
        <div className="grid grid-cols-2 gap-2 sm:gap-4 md:gap-2 lg:gap-2 xl:gap-4 md:grid-cols-4 lg:grid-cols-4 xl:grid-cols-6 max-w-screen-2xl mx-auto">
          {[
            { src: logo1, alt: "Brand 1" },
            { src: logo2, alt: "Brand 2" },
            { src: logo3, alt: "Brand 3" },
            { src: logo4, alt: "Brand 4" },
            { src: logo5, alt: "Brand 5" },
            { src: logo6, alt: "Brand 6" },
            { src: logo7, alt: "Brand 7" },
            { src: logo8, alt: "Brand 8" },
            { src: logo9, alt: "Brand 9" },
            { src: logo10, alt: "Brand 10" },
            { src: logo11, alt: "Brand 11" },
            { src: logo12, alt: "Brand 12" },
          ].map((brand) => (
            <div
              className="flex justify-center items-center"
              key={brand.alt}
              style={{ aspectRatio: "1/1" }}
            >
              <div className="w-full h-full bg-white rounded-lg shadow-lg flex justify-center items-center">
                <img
                  src={brand.src}
                  alt={brand.alt}
                  className="w-full h-auto object-cover"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Brands;
