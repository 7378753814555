import React from 'react';
import { AiOutlinePhone, AiOutlineMail, AiOutlineEnvironment } from 'react-icons/ai';

const Header = () => {
  return (
    <header id='/' className="hidden sm:flex bg-gradient-to-r from-black to-gray-400 text-white py-1 px-4 sm:py-1 sm:px-6 items-center justify-between">
      <div>
        {/* <img
          src="https://www.freepnglogos.com/uploads/mercedes-logo-png/mercedes-logo-mercedes-benz-logo-png-transparent-svg-vector-bie-13.png"
          alt="Company Logo"
          className="h-10 w-auto"
        /> */}
      </div>
      <div className="flex flex-col sm:flex-row items-center content-center sm:gap-2 ml-auto">
        <div className="mr-2 mb-2 sm:mb-0">
          <a
            href="tel:+254722383838"
            className="flex items-center font-semibold text-white hover:text-gray-300 text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiOutlinePhone className="h-5 w-5 inline-block mr-1" />
            <span>+254 (0) 7223838383</span>
          </a>
        </div>
        <div className="mr-2 mb-2 sm:mb-0">
          <a
            href="mailto:europeanstar@ymail.com"
            className="flex items-center font-semibold text-white hover:text-gray-300 text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiOutlineMail className="h-5 w-5 inline-block mr-1" />
            <span>europeanstar@ymail.com</span>
          </a>
        </div>
        <div>
          <a
            href="https://goo.gl/maps/bhms4UoujmeHMirQ8"
            className="flex items-center font-semibold text-white hover:text-gray-300 text-sm"
            target="_blank"
            rel="noopener noreferrer"
          >
            <AiOutlineEnvironment className="h-5 w-5 inline-block mr-1" />
            <span>Enterprise Road, Opp DTB bank</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
