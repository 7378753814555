import React from 'react';
import { motion } from 'framer-motion';
import logo1 from "../assets/Products/auger1.jpg"
import logo2 from "../assets/Products/auger2.jpg"
import logo3 from "../assets/Products/auger3.jpg"

const AboutUs = () => {
  const values = [
    'Commitment to Customer Satisfaction',
    'Reliability and Trustworthiness',
    'Quality and Excellence in Service',
    'Dedication to Innovation',
    'Integrity and Transparency',
    'Environmental Responsibility',
  ];

  return (
    <div id='about' className="bg-gray-300 min-h-screen">
      <header className="py-8 px-4 sm:px-6 bg-gradient-to-r from-black to-white">
        <motion.h1
          className="text-4xl font-bold text-center text-white"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
         About us
        </motion.h1>
      </header>
      <div className="container mx-auto py-12 px-4 sm:px-6">
        <div className="flex flex-col lg:flex-row items-center justify-center lg:justify-between">
          <div className="lg:w-1/3">
            <div className="flex flex-col mb-6">
              {/* Image 1 */}
              <img
                src={logo1}
                alt="Image 1"
                className="w-full h-auto rounded-lg mb-2 transition-all duration-500 transform hover:scale-105"
                style={{ maxWidth: '300px' }}
              />
            </div>
            <div className="flex flex-col mb-6">
              {/* Image 2 */}
              <img
                src={logo2}
                alt="Image 2"
                className="w-full ml-10 h-auto rounded-lg mb-2 transition-all duration-500 transform hover:scale-105"
                style={{ maxWidth: '200px' }}
              />
            </div>
            <div className="flex flex-col">
              {/* Image 3 */}
              <img
                src={logo3}
                alt="Image 3"
                className="w-full h-auto rounded-lg transition-all duration-500 transform hover:scale-105"
                style={{ maxWidth: '250px' }}
              />
            </div>
          </div>
          <div className="lg:w-2/3 lg:pl-12">
            <motion.h2
              className="text-4xl font-bold italic mb-6 text-blue-500 text-center"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Welcome to European Star
            </motion.h2>
            <p className="text-lg text-gray-700 leading-relaxed text-center">
              European Star is a leading company specializing in the sales of premium Mercedes truck spare parts in Nairobi, Kenya. With over 20 years of experience, European Star has established itself as a reliable provider of high-quality spare parts for Mercedes trucks in Kenya. Our dedication to customer satisfaction and our commitment to excellence sets us apart in the industry.
            </p>
            <p className="text-lg text-gray-700 mt-4 leading-relaxed text-center">
              As a subsidiary of Fanface, founded in 2002, European Star inherits over two decades of experience and expertise in the automotive industry. We take pride in our strong work ethic, determination, and unwavering focus on meeting our customers' needs.
            </p>
            <p className="text-lg text-gray-700 mt-4 leading-relaxed text-center">
              European Star is proud to be the authorized sole agent of <a href="https://www.iamauger.com/en" className="text-red-500 italic font-bold hover:text-blue-500">
                <span className="gradient-text-red-blue">Auger</span> Germany
              </a>. Auger Germany, renowned for its exceptional engineering and innovation, provides a wide range of top-quality spare parts for trucks. Our partnership with Auger, allows us to offer our customers the best products and ensure the optimal performance of their trucks.
            </p>
            <p className="text-lg text-gray-700 mt-4 leading-relaxed text-center">
              At European Star, we are driven by our commitment to providing exceptional service, delivering superior quality spare parts, and meeting the diverse needs of our customers. With our extensive inventory and knowledgeable team, we strive to be the preferred choice for truck owners and operators in Kenya.
            </p>
            <div className="mt-8">
              <h3 className="text-2xl font-bold mb-4 text-gray-800 text-center">Our Values</h3>
              <ul className="flex flex-wrap justify-center gap-1">
                {values.map((value, index) => (
                  <li
                    key={index}
                    className="bg-slate-800 rounded-full py-2 px-4 mb-2 text-white text-center"
                  >
                    {value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
