import React from "react";
import { FaWhatsapp, FaPhone } from "react-icons/fa";
import { motion } from "framer-motion";
import Header from "./components/Header";
import Navbar from "./components/NavBar";
import Landing from "./components/Landing";
import Products from "./components/Products";
import AboutUs from "./components/AboutUs";
import Brands from "./components/Brands";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Toast from "./components/Toast";

function App() {
  return (
    <div className="App">
      <Toast />
      <Header />
      <Navbar />
      <Landing />
      <Products />
      <Brands />
      <AboutUs />
      <ContactUs />
      <Footer />

      {/* Floating Icons Container */}
      <div className="fixed left-2 bottom-2 flex flex-col items-center sm:hidden space-y-4">
        {/* WhatsApp Icon */}
        <motion.a
          href="https://wa.me/254722383838" // Replace with your WhatsApp number
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center w-16 h-16 bg-green-500 rounded-full text-white hover:bg-green-600 transition-colors duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaWhatsapp className="text-3xl" />
          </motion.span>
        </motion.a>

        {/* Phone Icon */}
        <motion.a
          href="tel:+254722383838" // Replace with your phone number
          className="flex items-center justify-center w-16 h-16 bg-blue-500 rounded-full text-white hover:bg-blue-600 transition-colors duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaPhone className="text-3xl" />
          </motion.span>
        </motion.a>
      </div>

      {/* Floating Icons for Desktop */}
      <div className="hidden sm:flex flex-col items-center space-y-4 fixed right-2 bottom-2">
        {/* WhatsApp Icon */}
        <motion.a
          href="https://wa.me/254722383838" // Replace with your WhatsApp number
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center w-16 h-16 bg-green-500 rounded-full text-white hover:bg-green-600 transition-colors duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaWhatsapp className="text-3xl" />
          </motion.span>
        </motion.a>

        {/* Phone Icon */}
        <motion.a
          href="tel:+254722383838" // Replace with your phone number
          className="flex items-center justify-center w-16 h-16 bg-blue-500 rounded-full text-white hover:bg-blue-600 transition-colors duration-300"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          <motion.span
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            <FaPhone className="text-3xl" />
          </motion.span>
        </motion.a>
      </div>
    </div>
  );
}

export default App;
